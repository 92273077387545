<template>
  <div class="footer">
    <div class="content w">
      <div class="firstbox">
        <div class="ourselves">
          <img src="../assets/ourselves.png" width="22px" height="22px" alt="">
          <div class="textour">关于我们</div>
        </div>
        <div class="erweima">
          <div class="firster">
            <img src="../assets/zherweima.png" width="80px" height="80px" alt="">
            <div class="erweimatext">服务号二维码</div>
          </div>
          <div class="seconder">
            <img src="../assets/studenterweima.png" width="80px" height="80px" alt="">
            <div class="erweimatext">学生端就业小程序二维码</div>
          </div>
        </div>
        <div class="btmfirstbox">Copyrightⓒ goworkla.cn All Rights Reserved Ver 3.1.13</div>
      </div>
      <div class="secondbox">
        <div class="ourselves">
          <img src="../assets/phone.png" width="22px" height="22px" alt="">
          <div class="textour">联系我们</div>
        </div>
        <div class="message">
          <div class="adress">
            <div class="leftadress">地址：</div>
            <div class="rightadress">
              <div class="dong">东校区：河南郑州郑东新区龙子湖文苑西路15号</div>
              <div class="dong">南校区：河南郑州大学路2号</div>
            </div>
          </div>
          <div class="samestyle">
            邮箱：jyb@zua.edu.cn
          </div>
          <div class="samestyle">
            电话：0371-61912535
          </div>
          <div class="samestyle">
             档案资讯：0371-61912536
          </div>
        </div>
        <div class="btmfirstbox"></div>
      </div>
      <div class="thirdbox">
        <div class="allselect">
          <el-select v-model="value" placeholder="公共就业服务平台">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="allselect">
          <el-select v-model="value" placeholder="人才招聘网站">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="allselect">
          <el-select v-model="value" placeholder="教学院系">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="allselect">
          <el-select v-model="value" placeholder="用人单位">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [],
      value: ''
    }
  }
}
</script>

<style scoped lang="less">
.footer{
  height: 300px;
  width: 100%;
  background-color: #004D9B;
  .content{
    padding: 38px 56px 0px;
    box-sizing: border-box;
    display: flex;
    .firstbox{
      width: 316px;
      .ourselves{
        display: flex;
        align-items: center;
        padding-bottom: 17px;
        border-bottom: 1px solid #FFFFFF;
        .textour{
          margin-left: 14px;
          font-size: 16px;
          font-weight: bold;
          color: #fff;
        }
      }
      .erweima{
        display: flex;
        margin-top: 13px;
        padding-bottom: 14px;
        border-bottom: 1px solid #FFFFFF;
        .erweimatext{
          color: #FFFFFF;
          font-size: 14px;
          margin-top: 12px;
        }
        .seconder{
          margin-left: 40px;
        }
      }
      .btmfirstbox{
        color: #FFFFFF;
        font-size: 12px;
        margin-top: 28px;
      }
    }
    .secondbox{
      width: 316px;
      margin-left: 106px;
      .ourselves{
        display: flex;
        align-items: center;
        padding-bottom: 17px;
        border-bottom: 1px solid #FFFFFF;
        .textour{
          margin-left: 14px;
          font-size: 16px;
          font-weight: bold;
          color: #fff;
        }
      }
      .message{
        color: #FFFFFF;
        font-size: 12px;
        border-bottom: 1px solid #FFFFFF;
        padding-bottom: 21px;
        .adress{
          margin-top: 10px;
          display: flex;
        }
        .samestyle{
          margin-top: 10px;
        }
      }
      .btmfirstbox{
        color: #FFFFFF;
        font-size: 12px;
        margin-top: 28px;
      }
    }
    .thirdbox{
      margin-left: 118px;
      .allselect{
        margin-bottom: 20px;
      }
      /deep/.el-input__inner{
        height: 40px;
        width: 194px;
        color: #666;
        font-size: 14px;
        background-color: rgba(255, 255, 255, 0.9);
      }
    }
  }
}
</style>
